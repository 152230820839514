import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {CustomOverlayRef, MODAL_DATA} from '@modules/modal';

@Component({
  selector: 'app-finance-trial-warning-modal',
  templateUrl: './trial-warning-modal.component.html',
  styleUrls: ['./trial-warning-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialWarningModalComponent {

  constructor(
    @Inject(MODAL_DATA) public readonly modalData: {message: string},
    @Inject(CustomOverlayRef) private readonly overlayRef: CustomOverlayRef,
  ) {
  }

  onContinue(): void {
    this.close(true);
  }

  onCancel() {
    this.close(false);
  }

  private close(data: boolean | null = null): void {
    this.overlayRef.close(data);
  }

}
