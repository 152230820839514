import {Injectable} from '@angular/core';
import {OverlayService} from '@modules/modal';
import {EMPTY, of,Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {BoatShortInfoDto} from '@models/boat';
import {BoatSubscriptionTrialType} from '@models/boat-subscription';
import {BoatDetailsService} from '@services/boat-details/boat-details.service';

import {TrialWarningModalComponent} from '../components/trial-warning-modal/trial-warning-modal.component';

@Injectable()
export class TrialWarningService {

  constructor(
    private readonly overlayService: OverlayService,
    private readonly boatDetailsService: BoatDetailsService,
  ) {
  }

  open(message: string): Observable<boolean> {
    return this.boatDetailsService.boatShortInfo$
      .pipe(
        switchMap(info => {
          const showModal = this.showTrialMode(info);
          return showModal ? this.openModal(message) : of(true);
        }),
      );
  }

  private showTrialMode(boatInfo: BoatShortInfoDto): boolean {
    const {expenseCount, trialType} = boatInfo;
    return expenseCount === 0 && trialType === BoatSubscriptionTrialType.TrialUnlimited;
  }

  private openModal(message: string): Observable<boolean> {
    const ref = this.overlayService.open(
      TrialWarningModalComponent,
      {message},
      {
        panelClass: ['trial-warning-modal', 'modal-small'],
      },
      {
        disableCloseBtn: true,
        preventBackdropClick: true,
      },
    );
    return ref ? ref.afterClosed$.pipe(map(event => event.data)) : EMPTY;
  }
}
