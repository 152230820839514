<div class="modal-content">
  <app-seazone-icon name="warningTriangle" color="green" [width]="124" [height]="124"></app-seazone-icon>
  <div class="modal-content__wrap">
    <p [innerHTML]="modalData.message | transloco" class="modal-content__text"> </p>
  </div>
  <div class="modal-content__controls">
    <button class="main-btn main-btn_white-green" (click)="onCancel()">{{ 'actions.cancel' | transloco }}</button>
    <button class="main-btn main-btn_green" (click)="onContinue()">{{ 'actions.continue' | transloco }}</button>
  </div>
</div>
